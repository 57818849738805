import React, { FC, useCallback } from 'react'
import cx from 'classnames'

import { track } from 'utils/analytics'

import { RelatedStudy } from '../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import styles from './styles.module.scss'

interface Props extends RelatedStudy {
  className?: string
}

const CaseStudyLink: FC<Props> = ({ className, main_image, study_title, deck, context, url }) => {
  const handleCtaClick = useCallback(async () => {
    await track('click cta', { cta: 'case-study', module: 'more-case-studies', location: 'desktop-right-column' })

    if (!url) return

    window.location.href = url || ''
  }, [url])

  const srcset = [
    { img: main_image.thumbnail_1x, size: '1x' },
    { img: main_image.thumbnail_1x, size: '2x' },
  ]
    .filter((img) => !!img.img)
    .map((img) => `${img.img?.url} ${img.size}`)
    .join(',')

  return (
    <li className={cx(styles.caseStudyLink, className)}>
      <button className={styles.button} onClick={handleCtaClick}>
        <img src={main_image.thumbnail_1x.url} srcSet={srcset} alt={main_image.alt || ''} />
        <div className={styles.info}>
          <PresentRichText className={styles.context} render={context} />
          <PresentRichText className={styles.title} render={study_title} />
          <PresentRichText className={styles.deck} render={deck} />
        </div>
      </button>
    </li>
  )
}

export default CaseStudyLink
