import React, { FC, useState, useCallback, MouseEvent, TouchEvent } from 'react'
import cx from 'classnames'

import { SectionI } from '../Roundup/InstaStory/types'

import Image from './components/Participant/Image'
import useParticipants from './components/Participant/useParticipants'

import CloseIcon from 'svgs/close'

import styles from './StoryHud.module.scss'

interface Props {
  onClose?: () => void
  section: SectionI
}

const StoryHud: FC<Props> = ({ section, onClose }) => {
  const { advisors } = useParticipants()

  const [closed, setClosed] = useState(false)

  const handleMouseUp = useCallback(
    (event: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      if (closed) return // prevents calling close twice
      onClose?.()
      setClosed(true)
    },
    [closed, onClose]
  )

  const advisor = advisors[0]

  return (
    <div className={cx(styles.storyHud, { [styles.inverted]: !section.invert })}>
      <button className={styles.close} onMouseUp={handleMouseUp} onTouchEnd={handleMouseUp}>
        <CloseIcon />
      </button>
      {advisor && (
        <div className={styles.agent}>
          <div className={styles.info}>
            <span className={styles.label}>{'Working with'}</span>
            <span className={styles.name}>{advisor.display_name}</span>
          </div>
          <Image participant={advisor} />
        </div>
      )}
    </div>
  )
}

export default StoryHud
